<template>
    <div class="running-state-table">
        <sn-table-group
            ref="tableGroup"
            :tableGroupAttributes="tableGroupAttributes"
            :commonMethods.sync="commonMethods"
            @handleChange="handleChange"
            @getTableData="getTableData"
        >
        </sn-table-group>
    </div>
</template>

<script>
import { commonData } from "./state-table";
import commonMethods from "./state_table_common_methods";
import { mapActions } from "vuex";
export default {
    props: {
        sblxid: String,
    },
    data() {
        return {
            tableGroupAttributes: commonData,
            commonMethods,
            params: {
                sblxid: "",
                sbmc: "",
                yczcmc: "",
                ycysbm: "",
                sbzt: "",
                pageindex: 1,
                pagesize: 20,
            },
        };
    },
    watch: {
        sblxid() {
            this.getTableData();
        },
    },
    created() {
        this.initSelect();
    },
    mounted() {
        console.log(this.tableGroupAttributes);
        this.getTableData();
    },
    methods: {
        ...mapActions([
            "GetEnumItem",
            "getYcysqdList",
            "GetCompositions",
            "getEquipmentList",
        ]),
        async initSelect() {
            const res1 = await this.GetEnumItem({
                Enumid: "6dbe992a-5ebe-11ea-bd8d-000c2977b7fd",
            });
            // const res2 = await this.getYcysqdList();
            const res2 = await this.GetCompositions();
            console.log("GetCompositions------: ", res2);
            const yczcmc = this.tableGroupAttributes.snSearch.selectData.find(
                (ele) => ele.value === "yczcmc"
            );
            yczcmc.list = res2.RESULTVALUE;
            const sbzt = this.tableGroupAttributes.snSearch.selectData.find(
                (ele) => ele.value === "sbzt"
            );
            sbzt.list = res1;
        },
        handleChange(type, value) {
            console.log("type", type, "value", value);
            const [v, k] = value;

            switch (type) {
                case "searchBtn":
                    this.getTableData();
                    break;
                case "search":
                    this.params[k] = v;
                    console.log(this.params);
                    this.getTableData();
                    break;
                case "total": // 每页的条数
                    this.params.pagesize = value[0];
                    this.params.pageindex = 1;
                    this.tableGroupAttributes.snPage.tiaoshu = value[0];
                    this.tableGroupAttributes.snPage.currentPage = 1;
                    this.getTableData();
                    break;
                case "page": // 当前是第几页
                    this.params.pageindex = value[0];
                    this.tableGroupAttributes.snPage.currentPage = value[0];
                    this.getTableData();
                    break;
                case "columnSee":
                    this.columnSee(v);
                    break;
                case "buttonExport":
                    this.buttonExport();
                    break;
                default:
                    break;
            }
        },
        buttonExport() {
            // const token = localStorage.accessToken;
            const filename = "设备清单.xls";
            let obj = {
                url:
                    window.REQUEST_URL +
                    "EquipmentTesting/GetExportEquipmentList",
                name: `设备清单.xls`,
                para: {
                    token: localStorage.sysAccessToken,
                },
            };
            this.common.exportFile(obj);
        },
        columnSee(value) {
            console.log("--->", value);
            this.$emit("showDialog", value);
        },
        async getTableData() {
            const sblxid = this.sblxid;
            const params = { ...this.params, sblxid };
            this.tableGroupAttributes.snTable.table.loading = true;
            const res = await this.getEquipmentList(params);
            console.log("===>", res);
            res.DATA.map((item) => {
                item.AZSJ = item.AZSJ.split(" ")[0];
            });
            this.tableGroupAttributes.snTable.table.data = res.DATA || [];
            // this.columnSee(res.DATA[1])
            this.tableGroupAttributes.snPage.count = Number(res.TOTAL) || 0;
            this.tableGroupAttributes.loading = false;
        },
    },
};
</script>
<style lang="scss">
.running-state-table.right-box {
    .sn_table_group {
        background: #fff;
        .sn_tablebody {
            .el-table__header-wrapper {
                table {
                    thead {
                        tr {
                            th {
                                background: #eeeff3;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss" scoped>
.running-state-table {
}
</style>
