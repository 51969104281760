export const commonData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
            treeProps: {
                children: 'Children',
                hasChildren: 'hasChildren'
            }
        },
        columns: {
            // 选择、序号
            columnsOperate: [
                // {
                //     isShow: true,
                //     type: 'selection',
                //     width: 35
                // }, 
                {
                    isShow: true,
                    label: '序号',
                    type: 'index',
                    width: 50
                }
            ],
            // 数据列
            columnsData: [{
                prop: 'SBMC',
                label: '设备名称',
                width: "300"
            }, {
                prop: 'YCYSFL_2',
                label: '遗产组成',
            }, {
                prop: 'YCYSMC',
                label: '遗产要素',
            },
            {
                prop: 'SBBH',
                label: '设备编码',
            },
            {
                prop: 'SBWZ',
                label: '安装位置',
            },
            {
                prop: 'SBZT',
                label: '设备状态',
                transList: {
                    '故障': '故障',
                    '正常': '正常',
                },
                colorList: {
                    '故障': "#d2413e",
                    '正常': "#4ba1a5",
                },
            },
            {
                prop: 'AZSJ',
                label: '安装时间',
            },
                // {
                //     prop: 'REALNAME',
                //     label: '安装人',
                // },
            ],
            // 操作列
            columnsBtn: {
                width: 150,
                buttonData: [{
                    isShow: true,
                    showMethod: 'showSeeBtn',
                    btnType: 'icon',
                    operateType: 'columnSee',
                    title: '查看',
                    icon: 'el-icon-view',
                    backColor: 'transparent',
                    color: '#20a0ff'
                },]
            }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snCount: {
        eachtotal: [20, 50, 100],
        defaultTiaoshu: 20
    },
    snSearch: {
        inputData: [{
            label: '设备名称:',
            placeholder: "请输入关键字",
            value: 'sbmc',
            operateType: 'search',
            isShow: true
        }],
        selectData: [{
            // label: '遗产区:',
            label: '遗产组成:',
            clearable: true,
            // placeholder: "请选择遗产区",
            placeholder: "请选择遗产组成",
            list: [],
            // optionLabel: 'MC',
            // optionValue: 'BM',
            optionLabel: 'YCYSFL2',
            optionValue: 'YCYSFL2',
            value: 'yczcmc',
            operateType: 'search',
            isShow: true,
            width: '184px'
        },
        {
            label: '设备状态:',
            clearable: true,
            placeholder: "请选择设备状态",
            list: [],
            optionLabel: 'Name',
            optionValue: 'Code',
            value: 'sbzt',
            operateType: 'search',
            isShow: true,
            width: '184px'
        },
        ],
        searchBtn: {
            buttonData: [{
                btnType: 'button',
                operateType: 'searchBtn',
                name: '查询',
                round: true,
                backColor: '#2a63d5',
                color: '#fff'
            },
            {
                isShow: true,
                btnType: 'button',
                operateType: 'buttonExport',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            },
            ]
        }
    },
    snButton: {
        buttonData: []
    }
};