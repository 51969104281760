<template>
    <el-dialog
        class="equip-info"
        title="设备信息"
        :visible="dialogVisible"
        @close="handleClose"
        :close-on-click-modal="false"
        width="620px"
    >
        <div class="content">
            <div class="content-head">
                <div class="tab-box">
                    <span
                        v-for="tab in tabList"
                        :class="{ active: tab === curTab }"
                        :key="tab"
                        @click="curTab = tab"
                        >{{ tab }}</span
                    >
                </div>
            </div>
            <div class="content-body" v-if="curTab === tabList[0]">
                <ul>
                    <li>
                        <span>设备名称:</span>
                        <div>
                            <span>{{ detail.SBMC }}</span>
                        </div>
                    </li>
                    <li>
                        <span>设备编码:</span>
                        <div>
                            <span>{{ detail.SBBH }}</span>
                        </div>
                    </li>
                    <li>
                        <span>设备类型:</span>
                        <div>
                            <span>{{ detail.LXMC }}</span>
                        </div>
                    </li>
                    <li>
                        <span>监测对象:</span>
                        <div>
                            <span>{{ detail.YCYSMC }}</span>
                        </div>
                    </li>
                    <li>
                        <span>安装位置:</span>
                        <div>
                            <span>{{ detail.AZDWZMS || "暂无" }}</span>
                            <span class="btn-map" @click="showPickup = true"
                                >地图视图</span
                            >
                        </div>
                    </li>
                    <!-- <li>
                        <span>安装单位:</span>
                        <div>
                            <span>{{ detail.AZDW }}</span>
                        </div>
                    </li> -->
                    <li>
                        <span>采集周期:</span>
                        <div>
                            <span>{{ detail.ZQ }}</span>
                        </div>
                    </li>
                    <li>
                        <span>设备状态:</span>
                        <div>
                            <span>{{ detail.SBZT }}</span>
                        </div>
                    </li>
                    <li v-if="detail.FILE.length > 0">
                        <span>设备照片:</span>
                        <div style="display:block;" v-viewer>
                            <el-image
                                v-for="ele in detail.FILE"
                                fit="cover"
                                :src="ele.CCLJ"
                                :key="ele.CCLJ"
                            ></el-image>
                            <!-- <el-image  v-for="n in 5" fit="cover" src="https://t8.baidu.com/it/u=1484500186,1503043093&fm=79&app=86&size=h300&n=0&g=4n&f=jpeg?sec=1603788415&t=859ae3755e8be35b2009419e409406d7" :key="n"></el-image> -->
                        </div>
                    </li>
                </ul>
            </div>
            <div class="content-body" v-if="curTab === tabList[1]">
                <template v-for="ele in faultList">
                    <div
                        class="item error"
                        :key="ele.ID"
                        v-if="ele.JRJG === '异常'"
                    >
                        <div class="axis">
                            <div class="axis-item error">
                                <span>故障</span>
                                <i></i>
                            </div>
                        </div>
                        <div class="right">
                            <div>{{ ele.JCKSSJ }}</div>
                            <div>
                                <span>故障描述: </span>
                                <span>{{ ele.JRJGLXID }}</span>
                            </div>
                            <div>
                                <span>故障反馈: </span>
                                <span>{{ ele.FK }}</span>
                            </div>
                            <div class="carouselArrowShow" v-if="ele.LJ">
                                <span>故障图片: </span>
                                <el-carousel
                                    height="150px"
                                    indicator-position="none"
                                    v-viewer
                                    :autoplay="false"
                                >
                                    <el-carousel-item
                                        v-for="(item, index) in ele.LJ"
                                        :key="index"
                                    >
                                        <el-image
                                            fit="cover"
                                            :src="item"
                                        ></el-image>
                                    </el-carousel-item>
                                </el-carousel>
                            </div>
                        </div>
                    </div>
                    <div
                        class="item fixed"
                        :key="ele.ID"
                        v-if="ele.JRJG === '正常'"
                    >
                        <div class="axis">
                            <div class="axis-item fixed">
                                <span>恢复</span>
                                <i></i>
                            </div>
                        </div>
                        <div class="right">
                            <div>{{ ele.JCKSSJ }}</div>
                        </div>
                    </div>
                </template>
                <div v-if="faultList.length === 0">
                    暂无故障记录
                </div>
            </div>
        </div>
        <coordinatePickup
            title="地图位置"
            :showPickupP.sync="showPickup"
            :coordinate="coordinate"
            :icon="icon"
            @initMarker="initMarker"
            ref="pickup"
        ></coordinatePickup>
    </el-dialog>
</template>

<script>
import coordinatePickup from "@comp/map/coordinate_pickup";
import { mapActions } from "vuex";
export default {
    components: {
        coordinatePickup,
    },
    data() {
        return {
            showPickup: false,
            curTab: "设备信息",
            tabList: ["设备信息", "故障记录"],
            dialogVisible: false,
            title: "设备信息",
            detail: {
                AZDJD: null,
                AZDWD: null,
                AZDWZMS: null,
                ID: "",
                LXMC: "",
                SBBH: "",
                SBMC: "",
                SBZT: "",
                YCYSMC: "",
                ZQ: "",
                AZDW: "",
                FILE: [],
            },
            faultList: [],
            icon: window.L.icon({
                iconUrl: require("@image/map/icon/imarker2-2.png"),
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [0, -41],
            }),
        };
    },
    computed: {
        coordinate() {
            return `${this.detail.AZDJD},${this.detail.AZDWD}`;
        },
    },
    methods: {
        ...mapActions(["getEquipmentDetial", "getEquipmentFault"]),
        initMarker() {
            const wd = this.detail.AZDWD;
            const jd = this.detail.AZDJD;
            this.$refs.pickup.basicMap.flyTo([wd, jd]);
        },
        handleClose() {
            this.dialogVisible = false;
        },
        async getData(id) {
            const res1 = await this.getEquipmentDetial({ id });
            const res2 = await this.getEquipmentFault({ id });
            console.log("---->>>", res1);

            if (res1) {
                for (const key in res1) {
                    this.detail[key] = res1[key];
                }
            } else {
                this.detail = {
                    AZDJD: null,
                    AZDWD: null,
                    AZDWZMS: null,
                    ID: "",
                    LXMC: "",
                    SBBH: "",
                    SBMC: "",
                    SBZT: "",
                    YCYSMC: "",
                    ZQ: "",
                    AZDW: "",
                    FILE: [{}],
                };
            }
            if (Array.isArray(res2)) {
                this.faultList = res2;
            } else {
                this.faultList = [];
            }
        },
    },
};
</script>
<style lang="scss">
.equip-info {
    .el-dialog__header {
        padding-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #186d70;

        span,
        i {
            color: #fff;
        }
        button {
            position: static;
        }
    }
    .el-dialog__body {
        padding: 0;
    }
}
.equip-info {
    .content {
        .content-body {
            .el-form {
                .el-form-item {
                    margin-bottom: 0;
                }
            }
        }
    }
}
</style>
<style lang="scss" scoped>
$error: #ff9166;
$fixed: #2fc393;
.equip-info {
    .content {
        height: 500px;
        box-sizing: border-box;
        padding: 0 20px;
        .content-head {
            height: 50px;
            border-bottom: 1px dashed #ccc;
            position: relative;
            .tab-box {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                border-radius: 15px;
                overflow: hidden;
                span {
                    display: inline-block;
                    height: 30px;
                    line-height: 30px;
                    background: #dee1ea;
                    padding: 0px 30px;
                    color: #186d70;
                    cursor: pointer;
                    &.active {
                        background: #186d70;
                        color: #fff;
                        cursor: default;
                    }
                }
            }
        }
        .content-body {
            height: calc(100% - 50px);
            box-sizing: border-box;
            padding: 5px 0;
            overflow-y: auto;
            ul {
                margin-top: 10px;
                li {
                    height: 30px;
                    line-height: 30px;
                    display: flex;
                    margin-bottom: 10px;
                    > span {
                        flex: 0 0 80px;
                    }
                    > div {
                        flex: 1 1 0;
                        display: flex;
                        span {
                            flex: 1 1 0;
                            text-align: left;
                            display: inline-block;
                            border: 1px solid rgba($color: #2a63d6, $alpha: 0.2);
                            box-sizing: border-box;
                            padding: 0 5px;
                            border-radius: 5px;
                            &.btn-map {
                                margin-left: 10px;
                                flex: 0 0 100px;
                                text-align: center;
                                background: #186d70;
                                color: #fff;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
            > div.item {
                display: flex;
                &.error {
                    // height: 370px;
                    .right {
                        line-height: 30px;
                    }
                }
                &.fixed {
                    height: 40px;
                    .right {
                        line-height: 40px;
                    }
                }
                .axis {
                    flex: 0 0 65px;
                    position: relative;
                    // display: flex;
                    // flex-direction: column;
                    // justify-content: space-between;
                    &::after {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 2px;
                        height: 100%;
                        background: #ccc;
                        top: 0;
                        right: 9px;
                    }
                    .axis-item {
                        position: absolute;
                        top: 0;
                        width: 100%;
                        z-index: 9;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 16px;
                        line-height: 20px;
                        box-sizing: border-box;
                        padding: 10px 0;
                        i {
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                        }
                    }
                    .error {
                        color: $error;
                        i {
                            background: $error;
                        }
                    }
                    .fixed {
                        color: $fixed;
                        i {
                            background: $fixed;
                        }
                    }
                }
                .right {
                    flex: 1 1 0;
                    margin-left: 20px;
                }
            }
        }
    }
}
</style>
