<template>
    <div class="border">
        <sn-bread :itemName="itemName" :breadlist="breadlist"></sn-bread>
        <div class="state-sum">
            {{ `设备类型数量${SBLXCON}个,` }}
            {{ `设备数量${SBCON}个,` }}
            {{ `其中正常${ZC}个,正常率为${ZCV}%,` }}
            {{ `其中异常${YC}个,异常率为${YCV}%。` }}
        </div>
        <div class="stateContent flex-between">
            <div class="deviceTypeList pointer scrollbar">
                <div
                    v-for="(item, i) in STATISLIST"
                    :key="i"
                    @click="changeCurSblx(item)"
                    :class="{ active: item.SBLX === curSblx }"
                    class="deviceTypeItem"
                >
                    <span class="tag">{{ item.SBLXMC }}</span>
                    <div class="outer" :id="`chart${i + 1}`"></div>
                    <div
                        class="inner"
                        :style="{
                            backgroundImage: `url(${require(`@image/dataService_management/equip/${
                                sblxArr.indexOf(item.SBLXMC) !== -1
                                    ? item.SBLXMC
                                    : 'qt'
                            }.png`)})`,
                        }"
                    ></div>
                    <span class="box">
                        <span class="flex-box">
                            <span class="normal">正常:{{ item.ZC }}个</span>
                            <span class="abnormal">故障:{{ item.YC }}个</span>
                        </span>
                    </span>
                </div>
            </div>
            <state-table
                class="right-box flex-1"
                :sblxid="curSblx"
                @showDialog="showDialog"
            >
            </state-table>
        </div>
        <equip-info ref="dialog" />
    </div>
</template>
<script>
import StateTable from "./state_table";
import EquipInfo from "./equip_info";
import { mapGetters, mapActions } from "vuex";
import * as TYPES from "@store/modules/mutation-type.js";
export default {
    data() {
        return {
            isCheckFinished: false, // 全面检测是否已完成
            isStaticCircleClick: false, // 是否已开始全面检测
            breadlist: [
                {
                    path: "",
                    name: "数据管理与接入",
                },
            ],
            itemName: "设备监控",
            isDetailShow: false,
            isSbqdMapShow: false,
            mapPoint: [],
            SBLXCON: 0, //设备类型数量
            SBCON: 0, //设备数量
            ZC: 0, //正常
            ZCV: 0.0, //正常(百分比)
            YC: 0, //异常
            YCV: 0.0, //异常(百分比)
            STATISLIST: [],
            curSblx: "", // 点击后被选中 的 设备类型
            chartArr: [], // 左侧的 echarts 图表

            sblxArr: [
                "动物多样性监测器",
                "生态监测球机",
                "物联网自动虫情信息采集设备",
                "高精自动气象站",
                "一体式气象站",
                "土壤墒情监测站",
                "水质五参数监测器",
                "摄像机",
            ],
        };
    },
    components: {
        StateTable,
        EquipInfo,
    },
    computed: {
        ...mapGetters({
            TOTALLIST: TYPES.TOTALLIST,
        }),
    },
    methods: {
        ...mapActions([
            "getDevRunningStatusEx",
            "GetDevRunningStatus", // 获取运行状态
            "getSelectData",
            "checkSingleDataIsNormalEx",
        ]),
        // 画图
        draw() {
            this.STATISLIST.forEach((ele, i) => {
                const str = `chart${i + 1}`;
                const dom = document.getElementById(str);
                const temp = this.$echarts.init(dom);
                const option = this.getOption([ele.ZC, ele.YC]);
                temp.setOption(option);
            });
        },
        // 根据正常数量和异常数量 画饼图的 option
        getOption(list = [0, 0]) {
            const base = 30;
            const grow = 20;
            const sum = list[0] + list[1] || 1;
            const color = ["#ff9065", "#2dc393"];
            const num1 = +((grow * list[1]) / sum).toFixed() + base;
            const radiuList1 = [`${base}%`, num1 + "%"];
            const num2 = num1 + +((grow * list[0]) / sum).toFixed();
            const radiuList2 = [radiuList1[1], num2 + "%"];
            console.log(radiuList1, radiuList2);
            const series = [];
            // if(list[1]){
            //     series.push(createSeries({radius:radiuList1,color:color[0],value:list[1],z:5,name:'异常'}));
            // }
            // if(list[0]){
            //     series.push(createSeries({radius:radiuList2,color:color[1],value:list[0],z:1,name:'正常'}));
            // }
            series.push(createSeries());
            const option = {
                backgroundColor: "transparent",
                series,
            };
            return option;
            function createSeries() {
                return {
                    type: "pie",
                    radius: ["30%", "50%"],
                    center: ["50%", "50%"],
                    z: 5,
                    // startAngle: 190,
                    color: color,
                    data: [
                        {
                            hoverOffset: 1,
                            value: list[1],
                            name: "异常",
                            // itemStyle: {
                            //     color,
                            // },
                            label: {
                                normal: {
                                    show: true,
                                    formatter: `{r|异常{c}个\n({d}%)}`,
                                    rich: {
                                        r: {
                                            // color,
                                            fontWeight: "bold",
                                            fontSize: 14,
                                        },
                                    },
                                    position: "outside",
                                },
                            },
                            labelLine: {
                                normal: {
                                    lineStyle: {
                                        width: 2,
                                    },
                                },
                            },
                            hoverAnimation: false,
                        },
                        {
                            hoverOffset: 1,
                            value: list[0],
                            name: "正常",
                            // itemStyle: {
                            //     color,
                            // },
                            label: {
                                normal: {
                                    show: true,
                                    formatter: `{r|正常{c}个\n({d}%)}`,
                                    rich: {
                                        r: {
                                            // color,
                                            fontWeight: "bold",
                                            fontSize: 14,
                                        },
                                    },
                                    position: "outside",
                                },
                            },
                            labelLine: {
                                normal: {
                                    lineStyle: {
                                        width: 2,
                                    },
                                },
                            },
                            hoverAnimation: false,
                        },
                    ],
                };
            }
            function createSeries1({ radius, color, value, z, name }) {
                return {
                    type: "pie",
                    radius,
                    center: ["50%", "50%"],
                    z,
                    startAngle: 190,
                    data: [
                        {
                            hoverOffset: 1,
                            value,
                            name: "",
                            itemStyle: {
                                color,
                            },
                            label: {
                                normal: {
                                    show: true,
                                    formatter: `{r|${name}{c}个\n({d}%)}`,
                                    rich: {
                                        r: {
                                            color,
                                            fontWeight: "bold",
                                            fontSize: 14,
                                        },
                                    },
                                    position: "outside",
                                },
                            },
                            labelLine: {
                                normal: {
                                    lineStyle: {
                                        width: 2,
                                    },
                                },
                            },
                            hoverAnimation: false,
                        },
                    ],
                };
            }
        },
        // 展示对话框
        showDialog(value) {
            console.log("dialogVisible===>", value);
            this.$refs.dialog.dialogVisible = true;
            const id = value.ID;
            this.$refs.dialog.getData(id);
        },
        // 获取数据
        async getData() {
            const res = await this.getDevRunningStatusEx();
            console.log("getDevRunningStatusEx----->", res);
            if (res && res.TOTALOBJ && typeof res.TOTALOBJ === "object") {
                const temp = res.TOTALOBJ;
                for (const key in temp) {
                    const ele = temp[key];
                    this[key] = ele;
                }
            }
            if (res && Array.isArray(res.STATISLIST)) {
                this.STATISLIST = res.STATISLIST;
                setTimeout(() => {
                    this.draw();
                }, 0);
            }
        },
        // 监听全面检测
        handleStaticCircleClick() {
            this.isStaticCircleClick = true;
        },
        // 全面检测完成
        handleCheckFinashed(val) {
            if (val) {
                this.isStaticCircleClick = false;
                this.isCheckFinished = true;
            } else {
                this.isCheckFinished = false;
            }
        },
        // 地图展示
        showPointByMap() {
            this.isSbqdMapShow = true;
        },
        // 返回设备清单列表
        BackToSbqdTable() {
            this.isSbqdMapShow = false;
        },
        async getTableData() {
            let res = await this.getSelectData({
                _cols: [], //字段集
                _keyword: "", //查询关键字
                _serarchCols: "", //关键字字段集合
                _conditionList: [], //过滤条件集合
                _dicOrderby: {},
                _pageSize: null, //每页最大记录数
                _pageIndex: null, //页索引
                _returnSum: true, //是否返回记录总数
                _needVersion: false, //是否分版本查询
                _searchFormal: false, //T：查询最近的正式版本数据；F：查询最近的数据
                itemid: "116", //业务编号
            });
            if (res.data && res.data.length > 0) {
                this.mapPoint = [];
                res.data.forEach((item) => {
                    if (item.AZDJD && item.AZDWD) {
                        this.mapPoint.push(item);
                    }
                });
            } else {
                this.mapPoint = [];
            }
        },
        gotoDetail(val) {
            this.isDetailShow = val;
        },
        changeCurSblx(item) {
            if (this.curSblx === item.SBLX) {
                this.curSblx = "";
                this.getData();
            } else {
                this.curSblx = item.SBLX;
                this.getData();
            }
        },
    },
    created() {
        this.GetDevRunningStatus();
    },
    mounted() {
        this.getTableData();
        this.getData();
    },
};
</script>
<style scoped lang="scss">
$outer: #2ec392;
$inner: #ff9066;
.border {
    height: 100%;
    overflow: auto;
    .state-sum {
        height: 110px;
        line-height: 110px;
        font-size: 25px;
        box-sizing: border-box;
        padding: 0 45px;
        color: #2c4375;
        background: #fff;
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: 600;
        .checkBtn {
            display: inline-block;
            background: rgb(42, 99, 213);
            color: #fff;
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            border-radius: 15px;
            padding: 0 25px;
            letter-spacing: 3px;
        }
    }
    .stateContent {
        width: 100%;
        height: 647px;
        .deviceTypeList {
            width: 327px;
            height: 100%;
            .deviceTypeItem {
                width: 100%;
                height: 260px;
                position: relative;
                background: #fff;
                .tag {
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: #bfbfbf;
                    height: 30px;
                    line-height: 30px;
                    padding-left: 15px;
                    padding-right: 25px;
                    border-bottom-right-radius: 30px;
                    &::before {
                        width: 320px;
                        height: 3px;
                        background: #bfbfbf;
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: block;
                        content: "";
                    }
                }
                .inner {
                    border-radius: 50%;
                    padding: 39px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 10;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 40px;
                }
                .outer {
                    width: 100%;
                    height: 100%;
                }
                .box {
                    position: absolute;
                    bottom: 20px;
                    width: 100%;
                    padding: 0 25px;
                    .flex-box {
                        display: flex;
                        justify-content: space-between;
                        span {
                            display: inline-block;
                            padding-left: 20px;
                        }
                        span.normal {
                            color: $outer;
                            background: url("~@image/dataService_management/equip/正常个数.png")
                                no-repeat left center/14px;
                        }
                        span.abnormal {
                            color: $inner;
                            background: url("~@image/dataService_management/equip/异常个数.png")
                                no-repeat left center/14px;
                        }
                    }
                }
                &.active {
                    .tag {
                        background: rgb(42, 99, 213);
                        color: #fff;
                        &::before {
                            background: rgb(42, 99, 213);
                        }
                    }
                }
            }
        }
        .right-box {
            margin-left: 20px;
        }
    }
}

.top {
    height: 210px;
    margin: -1px 0px 20px;
}

.bottom {
    height: calc(100% - 290px);
}

.equip-map-content {
    position: relative;
    width: 100%;
    height: calc(100% - 70px);
}
.back-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 401;
    width: 100px;
    height: 30px;
    color: #fff;
    background-color: rgb(40, 204, 217);
    border: 1px solid rgb(40, 204, 217);
    border-radius: 20px;
    box-shadow: rgb(40, 204, 217) 0px 1px 6px;
    cursor: pointer;
}
.lonlat-map {
    width: 100%;
    height: 100%;
}
</style>
