// SHZT 0 提交未审核/已提交  1 未提交  2 审核通过  3 审核不通过
const ROLE_ID = window.ROLE_ID;
const commonMethods = {
    // 0 可查看  1 可编辑  2 可审核
    isPower(type) {
        let powerList = localStorage.monitoringPower.split(',');
        return powerList.indexOf(type) > -1;
        return true;
    },
    // 显示新建按钮 1
    showNewBtn() {
        let inPower = this.isPower('1');
        if (inPower) {
            return true;
        } else {
            return false;
        }
    },
    // 显示查看按钮
    showSeeBtn(row) {
        return !this.showEditBtn(row);
    },
    // 显示编辑按钮
    showEditBtn(row) {
        let inPower = this.isPower('1');
        if ((Number(row.SHZT) === 1 || Number(row.SHZT) === 3) && inPower) {
            return true;
        } else {
            return false;
        }
    },
    // 显示审核按钮
    showCheckBtn(row) {
        let inPower = this.isPower('2');
        if (row.SHZT !== '' && Number(row.SHZT) === 0 && inPower) {
            return true;
        } else {
            return false;
        }
    },
    // 显示删除按钮  只有超级管理员可以删除 f69d2068-9767-472f-8bb2-a04fa3d8055f
    showDelBtn(row) {
        let inPower = this.isPower('1') && localStorage.ROLEID === ROLE_ID.CJGLY;
        return inPower;
    },
    // 显示添加子节点的按钮（遗产要素清单）第三级以后不能再添加
    showAddBtn(row) {
        let inPower = this.isPower('1');
        if (Number(row.SHZT) === 2 && String(row.YCYSBM).length <= 21 && inPower) {
            return true;
        } else {
            return false;
        }
    },
    // 显示版本变更的按钮
    showRefreshBtn(row) {
        let inPower = this.isPower('1');
        if (Number(row.SHZT) === 2 && inPower) {
            return true;
        } else {
            return false;
        }
    },
    // 编辑页面的按钮
    // 保存
    showSubmit() {
        let inPower = this.isPower('1');
        if (inPower) {
            return true;
        } else {
            return false;
        }
    },
    // 审核
    showPass() {
        let inPower = this.isPower('2');
        if (inPower) {
            return true;
        } else {
            return false;
        }
    },
    columnIntegrat(row) {
        return false;
        // TODO
        // return row.SHZT === SHZT.AUDIT_PASSED;
    },
    // 1205 是否显示转为异常按钮
    showAbnormal(row) {
        let inPower = this.isPower('2');
        // 转为异常按钮显示条件： 不是审核未通过  类型为异常记录 登录用户有审核权限 之前未转为异常
        if (Number(row.SHZT) !== 3 && row.BQ == '0' && inPower && row.SFZYC != '1') {
            return true;
        } else {
            return false;
        }
    },
    showReply() {
        let inPower = this.isPower('2');
        if (inPower) {
            return true;
        } else {
            return false;
        }
    }
};
export default { commonMethods };